body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo-home {
  display: -webkit-flex;
  display: flex;
  padding: 40px;
  width: 200px;
  margin-right: auto;
}

.page-links {
  display: -webkit-flex;
  display: flex;
}

.page-links > i {
  margin-right: 20px;
}

a {
  text-decoration: none;
  color: dimgray;
}

a:hover, a:focus {
  color: gray;
}

.slogan {
  font-size: 36px;
  text-align: center;
}

.divider {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 25px 0px;
}

.divider-title {
  width: 30%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.broken-hr {
  background-color: black;
  height: 1px;
  width: 30%;
  margin-top: 12px;
}

.boxes {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

#human {
  font-weight: bolder;
}

#it {
  font-weight: bolder;
}

.contact-background {
  background-color: lightgray;
}

.control-error {
  border: 1px solid red;
}

.centered-rows {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.input-message-control {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin: 0px 20px;
  height: 100%;
  padding: 10px;
  background-color: lightgray;
  color: black;
  resize: none;
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.footer-elt {
  margin-right: 20px;
  margin-left: 20px;
}

.trans-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 20px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: rgba(0,0,0, .3);
  padding: 10px;
  width: 480px;
}

.side-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px;
  padding: 20px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow-y: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding-bottom: 25px;
  font-weight: bold;
  color: lightgray;
}

.bm-item:focus {
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.form-control {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

